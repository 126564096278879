
export const lightTheme = {
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: "#252525",
    },
    secondary: {
      main: "#9400D3",
    },
    highlighted: {
      main: "#efefef"
    },
    tableRow: {
      info: "#FFFFFF",
      warning: "#FFF4E5",
      error: "#FDEDED",
      success: "#EDF7ED",
      unread: "#F2F6FC",
    },
    text: {
      primary: "#252525",
    },
    background:{
      orders: '#f5f5f5',
    }
  },
}

export const darkTheme = {
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: "#dddddd",
    },
    secondary: {
      main: "#EE82EE",
    },
    highlighted: {
      main: "#181818"
    },
    tableRow: {
      info: "#071318",
      warning: "#191207",
      error: "#160b0b",
      success: "#0C130D",
      unread: "#121212",
    },
    text: {
      primary: "#dddddd",
    },
    background:{
      orders: '#212121',
    },
    mode: 'dark'
  },
}