export const layoutTrans = {
  layout: {
    main_nav: {
      parties: "Parties",
      transactions: "Transactions",
      events: "Events",
      alerts: "Alerts",
      tasks: "Tasks",
      trainings: "Trainings",
    },
    add_nav: {
      tools: "Tools",
      contact: "Contact",
      help: "Help",
    },
    select: {
      rule_settings: "Rule settings",
      dashboard:"Dashboard",
      orders: "Orders",
      products: "Products",
      subscription: "Subscription",
      settings: "Account Settings",
      log_switch: "Log switch (admin)",
      payments: "Payments",
      cart: "Cart",
    },
    logout_text: "Are you sure you want to log out?",
    subscription: {
      error: "The account does not have an active subscription!",
    }
  },
  landing_layout: {
    price_list: "Price list",
    compendium: "Compendium",
    procedure_aml: "AML procedure",
    services: "Services",
    services_submenu: {
      sanction_lists: "Sanction Lists",
    },
  },
};
