import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import {
  Menu,
  MenuItem,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Link, navigate } from "gatsby";

import i18next, { t } from "i18next";
import { commonTrans as enCommonTrans } from "../../../locales/en/common";
import { commonTrans as plCommonTrans } from "../../../locales/pl/common";
import { ADD_SHOP_ITEM, CHECK_SUBSCRIPTION } from "../../constants";

i18next.addResourceBundle("en", "common", enCommonTrans);
i18next.addResourceBundle("pl", "common", plCommonTrans);

function mapStateToProps(state) {
  return {};
}

const UserMenu = ({
  user,
  handleClick,
  setSubscriptionError = () => {},
  anchorEl,
  setAnchorEl,
  menuOpen,
  cartItemsNumber = (typeof window !== "undefined" && window.localStorage.getItem("cartItemsNumber")) || 0,
  ...props
}) => {
  const isAdmin = user.roles?.includes("admin");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAndNavigate = (path) => {
    setAnchorEl(null);
    if (
      path === "/dashboard/settings?tab-primary=storage" ||
      path === "/dashboard/risk-management?tab-primary=parties" ||
      path === "/dashboard/subscriptions" ||
      path === "/dashboard/orders" ||
      path === "/dashboard"
    ) {
      navigate(path);
    } else if (path === "/dashboard/products" || path === "/dashboard/cart") {
      props.actions.clearState(ADD_SHOP_ITEM);
      navigate(path);
    } else if (!props.location?.search.startsWith("?code=") && path === "/dashboard/storage") {
      navigate(path);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setAnchorEl(null);
    setDialogOpen(true);
  };

  const callLogout = () => {
    setDialogOpen(false);
    props.actions.userLogout();
    setSubscriptionError();
    props.actions.clearState(CHECK_SUBSCRIPTION);
    localStorage.setItem("cartItemsNumber", 0);
    navigate("/user/login/");
  };

  return (
    <>
      <Menu
        id="menu"
        aria-labelledby="menu-open-button"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleCloseAndNavigate("/dashboard/risk-management?tab-primary=parties")}>
          {t("layout:layout.select.rule_settings", "Rule settings")}
        </MenuItem>
        <MenuItem onClick={() => handleCloseAndNavigate("/dashboard/settings?tab-primary=storage")}>
          {t("layout:layout.select.settings", "Settings")}
        </MenuItem>

        <Divider />
        <MenuItem onClick={() => handleCloseAndNavigate("/dashboard/products")}>
          {t("layout:layout.select.products", "Products")}
        </MenuItem>
        <MenuItem onClick={() => handleCloseAndNavigate("/dashboard/cart")}>
          {t("layout:layout.select.cart", "Cart")} ({cartItemsNumber})
        </MenuItem>
        <MenuItem onClick={() => handleCloseAndNavigate("/dashboard/orders")}>
          {t("layout:layout.select.orders", "Orders")}
        </MenuItem>

        {isAdmin && (
          <>
            <Divider />
            <MenuItem component={Link} to="/dashboard/admin/logswitch">
              {t("layout:layout.select.log-switch", "Log switch (admin)")}
            </MenuItem>
          </>
        )}
        <Divider />
        <MenuItem onClick={handleDialogOpen}>{t("common:logout")}</MenuItem>
      </Menu>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <DialogContentText>{t("layout:layout.logout_text")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleDialogClose}>
            {t("common:cancel")}
          </Button>
          <Button variant="contained" color="secondary" onClick={callLogout}>
            {t("common:logout")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
