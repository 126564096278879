export const ROOT_API_URL = `${process.env.GATSBY_API_URL}/api`;
export const ROOT_URL = `${process.env.GATSBY_API_URL}`;
export const CONTACT_EMAIL = `${process.env.GATSBY_CONTACT_EMAIL}`;
export const CONTACT_PHONE_NUMBER = `${process.env.GATSBY_CONTACT_PHONE_NUMBER}`;
export const CONTACT_CALENDLY = `${process.env.GATSBY_CONTACT_CALENDLY}`;
export const FIBERPAY_NAME = `${process.env.GATSBY_FIBERPAY_NAME}`;
export const FIBERPAY_NAME_SHORT = `${process.env.GATSBY_FIBERPAY_NAME_SHORT}`;
export const FIBERPAY_TAX_NO = `${process.env.GATSBY_FIBERPAY_TAX_NO}`;
export const FIBERPAY_REGON = `${process.env.GATSBY_FIBERPAY_REGON}`;
export const FIBERPAY_KRS = `${process.env.GATSBY_FIBERPAY_KRS}`;
export const FIBERPAY_STREET = `${process.env.GATSBY_FIBERPAY_STREET}`;
export const FIBERPAY_POST_CODE = `${process.env.GATSBY_FIBERPAY_POST_CODE}`;
export const FIBERPAY_CITY = `${process.env.GATSBY_FIBERPAY_CITY}`;
export const FIBERPAY_COUNTRY_CODE = `${process.env.GATSBY_FIBERPAY_COUNTRY_CODE}`;
export const FIBERPAY_FRONTEND_URL = `${process.env.GATSBY_FIBERPAY_FRONTEND_URL}`;
export const FILE_UPLOAD_MAX_SIZE = `${process.env.GATSBY_FILE_UPLOAD_MAX_SIZE}`;


export const TOGGLE_SUBSCRIPTION_STATUS = 'TOGGLE_SUBSCRIPTION_STATUS';
export const GET_SUBSCRIPTION_STATUS = 'GET_SUBSCRIPTION_STATUS';

export const GENERAL_ERROR = 'GENERAL_ERROR';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const GET_USER = 'GET_USER';
export const USER_RESEND_VERIFICATION_EMAIL = 'USER_RESEND_VERIFICATION_EMAIL';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_SEND_RESET_PASSWORD_EMAIL = 'USER_SEND_RESET_PASSWORD_EMAIL';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_EMAIL_VERIFICATION = 'USER_EMAIL_VERIFICATION';
export const DELETE_USER = 'DELETE_USER';
export const USER_SEND_DELETE_USER_EMAIL = 'USER_SEND_DELETE_USER_EMAIL';
export const GET_USER_CONSENTS = "GET_USER_CONSENTS";
export const UPDATE_USER_CONSENTS = "UPDATE_USER_CONSENTS";

export const GET_PARTIES = 'GET_PARTIES';
export const GET_PARTY = 'GET_PARTY';
export const PREPARE_PARTY_REPORT = 'PREPARE_PARTY_REPORT';
export const GET_PARTY_RISK = 'GET_PARTY_RISK';
export const GET_PARTY_ATTACHMENTS = 'GET_PARTY_ATTACHMENTS';
export const GET_PARTY_ATTACHMENTS_ONE_DRIVE = 'GET_PARTY_ATTACHMENTS_ONE_DRIVE';
export const CREATE_PARTY = 'CREATE_PARTY';
export const UPDATE_PARTY = 'UPDATE_PARTY';
export const UPDATE_PARTY_STATUS = 'UPDATE_PARTY_STATUS';
export const DELETE_PARTY = 'DELETE_PARTY';
export const SET_PARTY_RISK_STATUS = 'SET_PARTY_RISK_STATUS';
export const UPLOAD_PARTY_CSV = 'UPLOAD_PARTY_CSV';
export const GET_PARTY_SUMMARY_STATUS = 'GET_PARTY_SUMMARY_STATUS';

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const GET_TRANSACTION_RISK = 'GET_TRANSACTION_RISK';
export const UPDATE_TRANSACTION_STATUS = 'UPDATE_TRANSACTION_STATUS';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_SUMMARY_STATUS = 'GET_TRANSACTION_SUMMARY_STATUS';
export const PREPARE_TRANSACTION_REPORT = 'PREPARE_TRANSACTION_REPORT';
export const GET_TRANSACTION_ATTACHMENTS = 'GET_TRANSACTION_ATTACHMENTS';
export const GET_TRANSACTION_ATTACHMENTS_ONE_DRIVE = 'GET_TRANSACTION_ATTACHMENTS_ONE_DRIVE';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const SET_TRANSACTION_RISK_STATUS = 'SET_TRANSACTION_RISK_STATUS';

export const GET_REPORT_LIST = 'GET_REPORT_LIST';

export const CREATE_HISTORY_EVENT = 'CREATE_HISTORY_EVENT';
export const UPDATE_HISTORY_EVENT = 'UPDATE_HISTORY_EVENT';
export const GET_HISTORY_EVENTS = 'GET_HISTORY_EVENTS';
export const GET_HISTORY_EVENT = 'GET_HISTORY_EVENT';
export const DELETE_HISTORY_EVENT = 'DELETE_HISTORY_EVENT';
export const GET_TRANSACTION_HISTORY_EVENTS = 'GET_TRANSACTION_HISTORY_EVENTS';

export const GET_EVENT_COMMENTS = 'GET_EVENT_COMMENTS';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const ADD_BENEFICIARY = 'ADD_BENEFICIARY';
export const DELETE_BENEFICIARY = 'DELETE_BENEFICIARY';
export const GET_BENEFICIARIES = 'GET_BENEFICIARIES';
export const UPDATE_BENEFICIARY = 'UPDATE_BENEFICIARY';

export const GET_BOARDMEMBERS = 'GET_BOARDMEMBERS';
export const ADD_BOARDMEMBER = 'ADD_BOARDMEMBER';
export const DELETE_BOARDMEMBER = 'DELETE_BOARDMEMBER';
export const UPDATE_BOARDMEMBER = 'UPDATE_BOARDMEMBER';

export const GET_REGON_AUTOFILL = 'GET_REGON_AUTOFILL';
export const GET_BENEFICIARIES_BY_NIP_FROM_CRBR = 'GET_BENEFICIARIES_BY_NIP_FROM_CRBR';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const CHECK_SUBSCRIPTION = 'CHECK_SUBSCRIPTION';
export const GET_SUBSCRIPTION_PLANS = 'GET_SUBSCRIPTION_PLANS';

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER = 'GET_ORDER';
export const GET_PRODUCTS_DATA = 'GET_PRODUCTS_DATA';
export const GET_ORDER_CALCULATION = 'GET_ORDER_CALCULATION';
// export const CREATE_ORDER = 'CREATE_ORDER';
// export const CANCEL_ORDER = 'CANCEL_ORDER';

export const GET_ALERTS = 'GET_ALERTS';
export const GET_ALERT = 'GET_ALERT';
export const UPDATE_ALERT_STATUS = 'UPDATE_ALERT_STATUS';
export const DELETE_ALERT = 'DELETE_ALERT';

export const CREATE_TASK = 'CREATE_TASK';
export const GET_TASKS = 'GET_TASKS';
export const GET_TASK = 'GET_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const DELETE_TASK = 'DELETE_TASK';

export const SURVEY_CHECK = 'SURVEY_CHECK';
export const SURVEY_SEND_EMAIL = 'SURVEY_SEND_EMAIL';
export const SANCTIONS_LISTS_SEARCH = 'SANCTIONS_LISTS_SEARCH';
export const SANCTIONS_LISTS_SEARCH_NO_FORM = 'SANCTIONS_LISTS_SEARCH_NO_FORM';
export const SANCTIONS_LISTS_SEARCH_TRANSACTION_NO_FORM = 'SANCTIONS_LISTS_SEARCH_TRANSACTION_NO_FORM';
export const PARTY_SANCTIONS_LISTS_SCREEN = 'PARTY_SANCTIONS_LISTS_SCREEN';
export const TRANSACTION_SANCTIONS_LISTS_SCREEN = 'TRANSACTION_SANCTIONS_LISTS_SCREEN';

export const GET_USER_INVOICE_DATA = 'GET_USER_INVOICE_DATA';
export const UPDATE_USER_INVOICE_DATA = 'UPDATE_USER_INVOICE_DATA';

export const GET_GOOGLE_AUTH = 'GET_GOOGLE_AUTH';
export const CREATE_GOOGLE_AUTH_TOKEN = 'CREATE_GOOGLE_AUTH_TOKEN';

export const GOOGLE_INFO = 'GOOGLE_INFO';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGOUT = 'GOOGLE_LOGOUT';
export const ADD_PARTY_ATTACHMENT = 'ADD_PARTY_ATTACHMENT';
export const ADD_PARTY_ATTACHMENT_ONE_DRIVE = 'ADD_PARTY_ATTACHMENT_ONE_DRIVE';
export const ADD_TRANSACTION_ATTACHMENT = 'ADD_TRANSACTION_ATTACHMENT';
export const ADD_TRANSACTION_ATTACHMENT_ONE_DRIVE = 'ADD_TRANSACTION_ATTACHMENT_ONE_DRIVE';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_ONE_DRIVE = 'DELETE_ATTACHMENT_ONE_DRIVE';
export const UPDATE_ATTACHMENT_DESCRIPTION = 'UPDATE_ATTACHMENT_DESCRIPTION';
export const UPDATE_ATTACHMENT_DESCRIPTION_ONE_DRIVE = 'UPDATE_ATTACHMENT_DESCRIPTION_ONE_DRIVE';

export const UPLOAD_TRANSACTION_SIGNED_XML = 'UPLOAD_TRANSACTION_SIGNED_XML';
export const UPLOAD_TRANSACTION_SIGNED_FORM_XML = 'UPLOAD_TRANSACTION_SIGNED_FORM_XML';

export const FINANCIAL_SECURITY_MEASURES_EXECUTIONS_GET = 'FINANCIAL_SECURITY_MEASURES_EXECUTIONS_GET';
export const FINANCIAL_SECURITY_MEASURES_EXECUTION_FIND = 'FINANCIAL_SECURITY_MEASURES_EXECUTIONS_FIND';
export const FINANCIAL_SECURITY_MEASURES_EXECUTION_CREATE = 'FINANCIAL_SECURITY_MEASURES_EXECUTIONS_CREATE';
export const DELETE_FINANCIAL_SECURITY_MEASURES_EXECUTION = 'DELETE_FINANCIAL_SECURITY_MEASURES_EXECUTION';

export const FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT = 'FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT';
export const FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT_ONE_DRIVE = 'FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT_ONE_DRIVE';

export const GET_RISK_MANAGEMENT_STATUS = 'GET_RISK_MANAGEMENT_STATUS';
export const UPDATE_RISK_MANAGEMENT_STATUS = 'UPDATE_RISK_MANAGEMENT_STATUS';
export const GET_AVAILABLE_RULES = 'GET_AVAILABLE_RULES';
export const GET_RULE = 'GET_RULE';
export const GET_RULE_LIMITS = 'GET_RULE_LIMITS';
export const GET_USER_RULES = 'GET_USER_RULES';
export const ADD_USER_RULE = 'ADD_USER_RULE';
export const REMOVE_USER_RULE = 'REMOVE_USER_RULE';
export const UPDATE_RULE_LIMITS = 'UPDATE_RULE_LIMITS';
export const ADD_MISSING_RULE_DATA = 'ADD_MISSING_RULE_DATA';
export const GET_SUGGESTED_RISK_STATUS_RECALCULATE = 'GET_SUGGESTED_RISK_STATUS_RECALCULATE';
export const REJECT_RULE_RESULTS = 'REJECT_RULE_RESULTS';
export const SET_RULE_RESULTS_STATUS = 'SET_RULE_RESULTS_STATUS';
export const UPDATE_HIGH_RISK_PKD_LIST = 'UPDATE_HIGH_RISK_PKD_LIST';

export const CREATE_HIGH_RISK_COUNTRY_LIST = 'CREATE_HIGH_RISK_COUNTRY_LIST';
export const UPDATE_HIGH_RISK_COUNTRY_LIST = 'UPDATE_HIGH_RISK_COUNTRY_LIST';
export const DELETE_HIGH_RISK_COUNTRY_LIST = 'DELETE_HIGH_RISK_COUNTRY_LIST';

export const CREATE_CORRUPT_COUNTRY_LIST = 'CREATE_CORRUPT_COUNTRY_LIST';
export const UPDATE_CORRUPT_COUNTRY_LIST = 'UPDATE_CORRUPT_COUNTRY_LIST';
export const DELETE_CORRUPT_COUNTRY_LIST = 'DELETE_CORRUPT_COUNTRY_LIST';

export const CREATE_INDIVIDUAL_CUSTOMER_PROFILE = 'CREATE_INDIVIDUAL_CUSTOMER_PROFILE';
export const GET_INDIVIDUAL_CUSTOMER_PROFILE = 'GET_INDIVIDUAL_CUSTOMER_PROFILE';
export const UPDATE_INDIVIDUAL_CUSTOMER_PROFILE = 'UPDATE_INDIVIDUAL_CUSTOMER_PROFILE';
export const DELETE_INDIVIDUAL_CUSTOMER_PROFILE = 'DELETE_INDIVIDUAL_CUSTOMER_PROFILE';

export const CREATE_TAX_HAVEN_COUNTRY_LIST = 'CREATE_TAX_HAVEN_COUNTRY_LIST';
export const UPDATE_TAX_HAVEN_COUNTRY_LIST = 'UPDATE_TAX_HAVEN_COUNTRY_LIST';
export const DELETE_TAX_HAVEN_COUNTRY_LIST = 'DELETE_TAX_HAVEN_COUNTRY_LIST';

export const CREATE_TERRORISM_COUNTRY_LIST = 'CREATE_TERRORISM_COUNTRY_LIST';
export const UPDATE_TERRORISM_COUNTRY_LIST = 'UPDATE_TERRORISM_COUNTRY_LIST';
export const DELETE_TERRORISM_COUNTRY_LIST = 'DELETE_TERRORISM_COUNTRY_LIST';

export const CREATE_SANCTION_COUNTRY_LIST = 'CREATE_SANCTION_COUNTRY_LIST';
export const UPDATE_SANCTION_COUNTRY_LIST = 'UPDATE_SANCTION_COUNTRY_LIST';
export const DELETE_SANCTION_COUNTRY_LIST = 'DELETE_SANCTION_COUNTRY_LIST';

export const GOOGLE_ACCOUNT_GET = 'GOOGLE_ACCOUNT_GET';
export const GOOGLE_ACCOUNT_CONNECTION_REDIRECT_URL_GET = 'GOOGLE_ACCOUNT_CONNECTION_REDIRECT_URL_GET';
export const GOOGLE_ACCOUNT_CONNECTION_CREATE = 'GOOGLE_ACCOUNT_CONNECTION_CREATE';
export const GOOGLE_ACCOUNT_DISCONNECT = 'GOOGLE_ACCOUNT_DISCONNECT';

export const MICROSOFT_ACCOUNT_GET = 'MICROSOFT_ACCOUNT_GET';
export const MICROSOFT_ACCOUNT_CONNECTION_REDIRECT_URL_GET = 'MICROSOFT_ACCOUNT_CONNECTION_REDIRECT_URL_GET';
export const MICROSOFT_ACCOUNT_CONNECTION_CREATE = 'MICROSOFT_ACCOUNT_CONNECTION_CREATE';
export const MICROSOFT_ACCOUNT_DISCONNECT = 'MICROSOFT_ACCOUNT_DISCONNECT';

export const LOG_SWITCH = 'LOG_SWITCH';

export const CREATE_APIKEYS = 'CREATE_APIKEYS';
export const GET_APIKEYS = 'GET_APIKEYS';
export const GET_ALL_ACTIVE_API_KEYS = 'GET_ALL_ACTIVE_API_KEYS';
export const DISABLE_APIKEYS = 'DISABLE_APIKEYS';

export const CREATE_EMPLOYEES = 'CREATE_EMPLOYEES';
export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const ADD_EMPLOYEE_TRAINING = 'ADD_EMPLOYEE_TRAINING';
export const GET_EMPLOYEE_TRAININGS = 'GET_EMPLOYEE_TRAININGS';
export const DELETE_EMPLOYEE_TRAINING = 'DELETE_EMPLOYEE_TRAINING';

export const MAKE_TEST_CALLBACK_REQUEST = 'MAKE_TEST_CALLBACK_REQUEST';
export const CREATE_APPLICANT = 'CREATE_APPLICANT';
export const GET_APPLICANT = 'GET_APPLICANT';
export const GET_APPLICANTS = 'GET_APPLICANTS';
export const SUBMIT_APPLICANT = 'SUBMIT_APPLICANT';

export const CREATE_REGISTRIES_SKAN_REPORT = 'CREATE_REGISTRIES_SKAN_REPORT';

export const CREATE_PPV_AML_PARTY = 'CREATE_PPV_AML_PARTY';
export const GET_PPV_REQUEST = 'GET_PPV_REQUEST';
export const GET_PPV_PARTY = 'GET_PPV_PARTY';
export const GET_PPV_RULE_SET = 'GET_PPV_RULE_SET';
export const GET_PPV_RULE_SET_DESCRIPTION = 'GET_PPV_RULE_SET_DESCRIPTION';
export const GET_PPV_PARTY_RISK = 'GET_PPV_PARTY_RISK';
export const PPV_REJECT_RULE_RESULTS = 'PPV_REJECT_RULE_RESULTS';
export const PPV_RECALCULATE_RESULTS = 'PPV_RECALCULATE_RESULTS';
export const UPDATE_PPV_PARTY = 'UPDATE_PPV_PARTY';
export const GET_PPV_EVENTS = 'GET_PPV_EVENTS';
export const GET_PPV_EVENT_DETAILS = 'GET_PPV_EVENT_DETAILS';
export const DELETE_PPV_EVENT = 'DELETE_PPV_EVENT';
export const GET_PPV_REPORT_DETAILS = 'GET_PPV_REPORT_DETAILS';
export const GET_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS = 'GET_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS';
export const DELETE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS = 'DELETE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS';
export const CREATE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS = 'CREATE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS';
export const PPV_FIND_DUE_DILIGENCE_EXECUTION = 'PPV_FIND_DUE_DILIGENCE_EXECUTION';
export const PPV_PREPARE_REPORT = 'PPV_PREPARE_REPORT';
export const PPV_GET_PDF_LIST = 'PPV_GET_PDF_LIST';
export const PPV_PARTY_SCREEN = 'PPV_PARTY_SCREEN';
export const SET_PPV_RULE_RESULT_STATUS = 'SET_PPV_RULE_RESULT_STATUS';
export const GET_PPV_BENEFICIARIES = 'GET_PPV_BENEFICIARIES';
export const DELETE_PPV_BENEFICIARY = 'DELETE_PPV_BENEFICIARY';
export const ADD_PPV_BENEFICIARY = 'ADD_PPV_BENEFICIARY';
export const UPDATE_PPV_BENEFICIARY = 'UPDATE_PPV_BENEFICIARY';
export const GET_PPV_BOARDMEMBERS = 'GET_PPV_BOARDMEMBERS';
export const ADD_PPV_BOARDMEMBER = 'ADD_PPV_BOARDMEMBER';
export const DELETE_PPV_BOARDMEMBER = 'DELETE_PPV_BOARDMEMBER';
export const UPDATE_PPV_BOARDMEMBER = 'UPDATE_PPV_BOARDMEMBER';
export const GET_PPV_RULE_LIMITS = 'GET_PPV_RULE_LIMITS';
export const GET_PPV_EVENT_COMMENTS = 'GET_PPV_EVENT_COMMENTS';
export const CREATE_PPV_EVENT_COMMENT = 'CREATE_PPV_EVENT_COMMENT';
export const DELETE_PPV_COMMENT = 'DELETE_PPV_COMMENT';
export const UPDATE_PPV_COMMENT = 'UPDATE_PPV_COMMENT';
export const CREATE_PPV_HISTORY_EVENT = 'CREATE_PPV_HISTORY_EVENT';
export const UPDATE_PPV_HISTORY_EVENT = 'UPDATE_PPV_HISTORY_EVENT';
export const ADD_PPV_MISSING_RULE_DATA = 'ADD_PPV_MISSING_RULE_DATA';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const GET_SHOP_PRODUCTS = 'GET_SHOP_PRODUCTS';
export const GET_SHOP_CART = 'GET_SHOP_CART';
export const ADD_SHOP_ITEM = 'ADD_SHOP_ITEM';
export const DELETE_SHOP_ITEM = 'DELETE_SHOP_ITEM';
export const UPDATE_SHOP_ITEM = 'UPDATE_SHOP_ITEM';
export const ADD_DISCOUNT_CODE = 'ADD_DISCOUNT_CODE';
export const DELETE_DISCOUNT_CODE = 'DELETE_DISCOUNT_CODE';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CANCEL_ORDER = 'CANCEL_ORDER';

export const GET_SESSION = 'GET_SESSION';


